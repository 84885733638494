<template>
  <div>
    <div class="authortitle">
      投稿<span @click="back()" style="float: left; cursor: pointer"
        ><i class="el-icon-arrow-left"></i
      ></span>
    </div>
    <div style="padding: 20px">
      <div class="qktitle">{{ qkdetail.name }}</div>
      <div class="qkcontent">
        <div class="qkcontent-img">
          <img :src="imgsrc" />
        </div>
        <div class="qkcontent-scrp">
          <ul>
            <li>
              <div>
                <p>
                  <span class="qkbt">期次：</span
                  ><span>{{ qkdetail.issue }}</span>
                </p>
                <p>
                  <span class="qkbt">国内刊号：</span
                  ><span>{{ qkdetail.guoneikanhao }}</span>
                </p>
                <p>
                  <span class="qkbt">期刊主编：</span
                  ><span>{{ qkdetail.qikaneditor }}</span>
                </p>
                <p>
                  <span class="qkbt">邮发代号：</span
                  ><span>{{ qkdetail.postnumber }}</span>
                </p>
                <p>
                  <span class="qkbt">发行地点：</span
                  ><span>{{ qkdetail.publishlocation }}</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <span class="qkbt">截稿日期：</span
                  ><span class="times">{{ qkdetail.deadline }}</span>
                </p>
                <p>
                  <span class="qkbt">国际刊号：</span
                  ><span>{{ qkdetail.issn }}</span>
                </p>
                <p>
                  <span class="qkbt">审稿时间：</span
                  ><span>{{ qkdetail.checktime }}</span>
                </p>
                <p>
                  <span class="qkbt">创刊时间：</span
                  ><span>{{ qkdetail.createtime }}</span>
                </p>
                <p>
                  <span class="qkbt">邮政编码：</span
                  ><span>{{ qkdetail.postalcode }}</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <span class="qkbt">预计出刊时间：</span
                  ><span class="times">{{ qkdetail.publishtime }}</span>
                </p>
                <p>
                  <span class="qkbt">出版周期：</span
                  ><span>{{ qkdetail.publishcycle }}</span>
                </p>
                <p>
                  <span class="qkbt">期刊级别：</span
                  ><span>{{ qikanlevels }}</span>
                </p>
                <p>
                  <span class="qkbt">期刊语言：</span
                  ><span>{{ qkdetail.qikanlanguage }}</span>
                </p>
                <p>
                  <span class="qkbt">期刊类别：</span
                  ><span>{{ qkdetail.types }}</span>
                </p>
              </div>
            </li>
          </ul>
          <div class="scrip-bottom">
            <p>
              <span class="qkbt">主管单位：</span
              ><span>{{ qkdetail.competentunit }}</span>
            </p>
            <p>
              <span class="qkbt">主办单位：</span
              ><span>{{ qkdetail.sponsor }}</span>
            </p>
            <p>
              <span class="qkbt">期刊收录：</span
              ><span>{{ qkdetail.include }}</span>
            </p>
            <p>
              <span class="qkbt">期刊荣誉：</span
              ><span>{{ qkdetail.qikanhonour }}</span>
            </p>
            <p>
              <span class="qkbt">杂志栏目：</span
              ><span>{{ qkdetail.journal }}</span>
            </p>
            <p>
              <span class="qkbt">影响因子：</span
              ><span>{{ qkdetail.yingxiangyinzi }}</span>
            </p>
            <el-button type="danger" size="mini" @click="contribute"
              >点击投稿</el-button
            >
            <el-button v-if="qkdetail.Collect == 0" size="mini" @click="addlike"
              >加入收藏</el-button
            >
            <el-button
              v-if="qkdetail.Collect == 1"
              size="mini"
              @click="deletelike"
              >取消收藏</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="xxk">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="期刊简介" name="first">
          <div class="qkjj" style="padding: 20px">
            {{ qkdetail.qikanintroduction }}
          </div>
        </el-tab-pane>
        <el-tab-pane label="投稿要求" name="second">
          <div
            style="
              padding: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
            "
          >
            {{ qkdetail.requirement }}
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

	<script>
import {
  showoneqikan,
  addfavorites,
  deleteaFvorites,
  resultMsg,
} from "../../../API/ZZD/magzine.js";
export default {
  data() {
    return {
      imgsrc: "",
      activeName: "first",
      qkid: "",
      qkdetail: "",
      qikanlevels: "",
    };
  },
  mounted() {
    console.log(this.qkid);
    this.qkid = this.$route.query.qkid;
    this.showqkdetail();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    showqkdetail() {
      let id = localStorage.getItem("ZZId");
      showoneqikan(id, this.qkid).then((res) => {
        console.log(res);
        this.imgsrc = this.URL + res.data.data.cover;
        this.qkdetail = res.data.data;
        if (this.qkdetail.qikanlevel == 1) {
          this.qikanlevels = "CSSCI南大核心期刊";
        } else if (this.qkdetail.qikanlevel == 2) {
          this.qikanlevels = "北大核心期刊";
        } else if (this.qkdetail.qikanlevel == 3) {
          this.qikanlevels = "CSCD核心期刊";
        } else if (this.qkdetail.qikanlevel == 4) {
          this.qikanlevels = "统计源期刊";
        } else if (this.qkdetail.qikanlevel == 5) {
          this.qikanlevels = "国家级期刊";
        } else if (this.qkdetail.qikanlevel == 6) {
          this.qikanlevels = "省级期刊";
        } else if (this.qkdetail.qikanlevel == 7) {
          this.qikanlevels = "SCI核心期刊";
        }
      });
    },
    addlike() {
      //加入收藏
      let a_id = localStorage.getItem("ZZId");
      addfavorites(this.qkdetail.qikan_id, a_id).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("收藏成功");
          this.showqkdetail();
        }
      });
    },
    deletelike() {
      //取消收藏
      let a_id = localStorage.getItem("ZZId");
      deleteaFvorites(this.qkdetail.qikan_id, a_id).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("取消收藏");
          this.showqkdetail();
        }
      });
    },
    contribute() {
      resultMsg(this.qkdetail.qikan_id).then((res) => {
        if (res.data.code == 0) {
          sessionStorage.setItem("title", this.qkdetail.include);
          sessionStorage.setItem("tgqk", this.qkdetail.qikan_id);
          sessionStorage.setItem("tgqkname", this.qkdetail.name);
          this.$router.push({ path: "/Pending/contribute" });
        } else {
          this.$message({
            showClose: true,
            message: "该期刊暂不支持投稿",
            type: "error",
          });
        }
      });
    },
    back() {
      history.back();
    },
  },
};
</script>

	<style>
.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.qktitle {
  height: 80px;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #141414;
  line-height: 80px;
}
.qkcontent {
  display: flex;
}

.qkcontent-img img {
  width: 100%;
  height: 80%;
}
.qkcontent-scrp {
  flex: 4;
  margin-left: 20px;
}
.qkcontent-scrp ul {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.qkcontent-scrp ul li {
  margin-right: 10px;
  line-height: 30px;
}
.scrip-bottom {
  font-size: 14px;
  line-height: 30px;
}
.qkbt {
  color: #666;
}
.xxk {
  margin-top: 20px;
}
.qkjj {
  padding: 0 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}
</style>